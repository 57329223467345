<template>
 <div class="inner-section mt-3">
    <bread-cumb />
       <body-card>
       <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('teaGarden.salary_sheet_staff') }} {{ $t('globalTrans.view') }}</h4>
      </template>
      <template v-slot:headerAction>
        <a href="javascript:" class="btn-add" @click="pdfExport"><i class="ri-printer-line"></i> {{ $t('globalTrans.print') }}</a>
      </template>
      <template v-slot:body>
          <b-overlay :show="loader">
          <b-container fluid>
              <b-row>
                <b-col lg="12" xs="12" md="12">
                    <b-row>
                        <b-col lg="12" xs="12" md="12">
                          <list-report-head :base-url="baseUrl" uri="/configuration/report-heading/detail" :org-id="5">
                            <template v-slot:projectNameSlot>
                            </template>
                            {{ $i18n.locale === 'en' ? 'SALARY SHEET (WORKER) REPORT' : 'বেতন শীট (শ্রমিক) প্রতিবেদন' }}
                          </list-report-head>
                        </b-col>
                    </b-row>
                    <b-row>
                      <b-col v-if="search.garden_id" style="font-size: 14px" sm="6">
                        <div>
                          <span>{{ $t('teaGarden.tea_garden') }}: </span>
                          <span><b>{{ getGardenName(search.garden_id) }}</b></span>
                        </div>
                      </b-col>
                      <b-col v-if="search.garden_id" style="font-size: 14px" sm="6">
                        <div class="text-right">
                          <span>{{ $t('teaGarden.tea_garden_id') }}: </span>
                          <span><b>{{ garden_id_no }}</b></span>
                        </div>
                      </b-col>
                    </b-row>
                </b-col>
              </b-row>
              <b-row>
              <!-- <b-overlay :show="loader"> -->
                <b-col v-if="salaries.length" lg="12" xs="12" md="12" class="mt-3 tableFixHead">
                    <b-table-simple bordered style="font-size: 13px">
                        <b-thead class="bg-light tableFixHead2">
                            <b-tr class="text-center align-middle">
                              <b-th rowspan="3">{{ $t('globalTrans.sl_no') }}</b-th>
                              <b-th rowspan="3">{{ $t('globalTrans.name') }}</b-th>
                              <b-th rowspan="3">{{ $t('teaGarden.brought_forward')  }}</b-th>
                              <b-th colspan="12">
                                {{ currentLocale == 'en' ? 'For the date between ' : 'তারিখ '}} {{search.from_date | dateFormat }}  {{ currentLocale == 'en' ? 'To ' : ' থেকে '}} {{search.to_date | dateFormat }}
                              </b-th>
                            </b-tr>
                            <b-tr>
                              <b-th v-for="(item, index) in salaries[0].heads[0].details" :key="index">
                                {{ getWorkingDay(item.date) }}
                              </b-th>
                              <b-th>{{ $t('teaGarden.total_wa_da_extra') }}</b-th>
                              <b-th>{{ $t('teaGarden.weeks_total') }}</b-th>
                              <b-th>{{ $t('teaGarden.less_pf') }}</b-th>
                              <b-th>{{ $t('teaGarden.balance_payable') }}</b-th>
                              <b-th>{{ $t('teaGarden.balance_carried') }}</b-th>
                            </b-tr>
                            <b-tr>
                              <b-th>{{ $t('globalTrans.taka') }}</b-th>
                              <b-th>{{ $t('globalTrans.taka') }}</b-th>
                              <b-th>{{ $t('globalTrans.taka') }}</b-th>
                              <b-th>{{ $t('globalTrans.taka') }}</b-th>
                              <b-th>{{ $t('globalTrans.taka') }}</b-th>
                              <b-th>{{ $t('globalTrans.taka') }}</b-th>
                              <b-th>{{ $t('globalTrans.taka') }}</b-th>
                              <b-th>{{ $t('globalTrans.taka') }}</b-th>
                              <b-th>{{ $t('globalTrans.taka') }}</b-th>
                              <b-th>{{ $t('globalTrans.taka') }}</b-th>
                              <b-th>{{ $t('globalTrans.taka') }}</b-th>
                              <b-th>{{ $t('globalTrans.taka') }}</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody v-for="(item, index) in salaries" :key="index">
                            <b-tr v-for="(item1, index1) in item.heads" :key="index1">
                              <b-td v-if="index1 === 0" :rowspan="item.heads.length">{{ $n(index + 1) }}</b-td>
                              <b-td v-if="index1 === 0" :rowspan="item.heads.length">
                                {{ (currentLocale === 'en' ? item.worker.worker_name_en : item.worker.worker_name_bn) + ' (' + item.worker.pf_id + ')' }}
                              </b-td>
                              <b-td>{{ parseInt(item1.head_id) ? getWegHeadName(item1.head_id) : $t('teaGarden.extra_earning') }}</b-td>
                              <b-td class="text-right align-middle" v-for="(item3, index3) in item1.details" :key="index3">
                                {{ $n(item3.amount, { minimumFractionDigits: 2 }) }}
                              </b-td>
                              <b-td class="text-right align-middle">{{ $n(item1.head_total, { minimumFractionDigits: 2 }) }}</b-td>
                              <b-td class="text-right align-middle" v-if="index1 === 0" :rowspan="item.heads.length">
                                {{ $n((item.heads.reduce((a, c) => a + c.head_total, 0)), { minimumFractionDigits: 2 }) }}
                              </b-td>
                              <b-td class="text-right align-middle" v-if="index1 === 0" :rowspan="item.heads.length">
                                {{ $n(item.pf, { minimumFractionDigits: 2 }) }}
                              </b-td>
                              <b-td class="text-right align-middle" v-if="index1 === 0" :rowspan="item.heads.length">
                                {{ $n((item.heads.reduce((a, c) => a + c.head_total, 0) - item.pf), { minimumFractionDigits: 2 }) }}
                              </b-td>
                              <b-td v-if="index1 === 0" :rowspan="item.heads.length"></b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
                <!-- </b-overlay> -->
              </b-row>
          </b-container>
          </b-overlay>
      </template>
     </body-card>
  </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { staffSalaryView } from '../../../api/routes'
import BreadCumb from '@/components/BreadCumb.vue'
import ListReportHead from '@/components/custom/TeaGardenReportHead.vue'
import moment from 'moment'

export default {
  name: 'Form',
  props: [],
  components: { BreadCumb, ListReportHead },
  data () {
    return {
      baseUrl: teaGardenServiceBaseUrl,
      valid: null,
      errors: [],
      salaries: [],
      garden_id_no: '',
      search: {},
      loader: false
    }
  },
  created () {
    window.scrollTo(0, 0)
    if (this.$route.query.garden_id) {
      this.search = this.$route.query
      this.loadData()
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    gdnWagesHeadList () {
      return this.$store.state.TeaGardenService.commonObj.gdnWagesHeadList.filter(el => el.status === 1 && JSON.parse(el.employee_types).includes(2))
    },
    masterTeaGardenGenInfoList () {
          return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(el => el.status === 1)
    },
    unitList () {
      let fundList = [
        { value: 1, text_en: 'KG', text_bn: 'কেজি' },
        { value: 2, text_en: 'Piece', text_bn: 'পিস' }
      ]
       fundList = fundList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
      return fundList
    }
  },
  watch: {
  },
  methods: {
    getWorkingDay (date) {
      require('moment/min/locales.min')
      moment.locale(this.$i18n.locale)
      return moment(date).format('DD')
    },
    getWegHeadName (Id) {
      const Obj = this.gdnWagesHeadList.find(el => el.value === parseInt(Id))
      if (typeof Obj !== 'undefined') {
        return this.currentLocale === 'en' ? Obj.text_en : Obj.text_bn
      }
      return ''
    },
    getCloneName (councilNameId) {
        const cateObj = this.$store.state.TeaGardenService.commonObj.masterCloneNameList.filter(item => item.status === 1).find(item => item.value === councilNameId)
        if (cateObj !== undefined) {
            if (this.$i18n.locale === 'bn') {
                return cateObj.text_bn
            } else {
                return cateObj.text_en
            }
        }
    },
    getUnitName (unitId) {
      const unitList = [
        { value: 1, text_en: 'KG', text_bn: 'কেজি' },
        { value: 2, text_en: 'Piece', text_bn: 'পিস' }
      ]
      const cateObj = unitList.find(item => item.value === unitId)
        if (cateObj !== undefined) {
            if (this.$i18n.locale === 'bn') {
                return cateObj.text_bn
            } else {
                return cateObj.text_en
            }
        }
    },
    getGardenName (Id) {
        const Obj = this.masterTeaGardenGenInfoList.find(el => el.value === parseInt(Id))
        if (typeof Obj !== 'undefined') {
          this.garden_id_no = Obj.garden_id_no
          return this.currentLocale === 'en' ? Obj.text_en : Obj.text_bn
        }
        return ''
    },
    async loadData () {
      this.loader = true
      const params = Object.assign({}, this.search)
      const result = await RestApi.getData(teaGardenServiceBaseUrl, staffSalaryView, params)
      if (result.success) {
          this.salaries = result.data
          this.loader = false
      } else {
        this.loader = false
      }
    },
    async pdfExport () {
        this.loader = true
        const params = {
          garden_id: this.search.garden_id,
          request_type: 'pdf',
          local: this.$i18n.locale,
          org_id: 5,
          garden_id_no: this.garden_id_no,
          from_date: this.search.from_date,
          to_date: this.search.to_date
        }

        const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, staffSalaryView, params)
        var blob = new Blob([result], {
          type: 'application/pdf'
        })
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.loader = false
    }
  }
}
</script>
<style scoped>
.tableFixHead { overflow: auto; height: 500px; }
.tableFixHead .tableFixHead2 { position: sticky; top: 0; z-index: 1;}
.font-bn .table-wrapper table thead tr th {
  font-size: 14px !important;
}
.font-bn .table-wrapper table tbody tr td {
  font-size: 14px !important;
}
</style>
